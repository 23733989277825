import axios, {AxiosResponse} from "axios";
import {API_BASE, API_BASE_BASE} from "./ApiConfig";
import {GameDataDto} from "../dto/GameDataDto";
import {DisplayData} from "../dto/DisplayData";
import {LanguageEnum} from "../dto/LanguageEnum";
import {BoardDto} from "../dto/BoardDto";
import {DisplayRow} from "../dto/display/DisplayRow";
import {UiData} from "../dto/display/UiData";

const API = API_BASE + "/game/streamer.php"

export function getGameData(streamer: string): Promise<AxiosResponse<GameDataDto>> {
    return axios.get(API+"?action=data&twitchName="+streamer);
}

export function getBoard(streamer: string): Promise<AxiosResponse<BoardDto>> {
    return axios.get(API+"?action=board&twitchName="+streamer);
}

/** returns the board date e.g. 2024-01-09 18:43:48 */
export function getBoardLastTime(streamer: string): Promise<AxiosResponse<string>> {
    return axios.get(API+"?action=boardLastTime&twitchName="+streamer);
}

export function getDisplayData(language: LanguageEnum = LanguageEnum.English): Promise<AxiosResponse<DisplayData>> {
    console.log(language.toString());
    return axios.get(API_BASE_BASE+"/displaydata/DisplayData-"+language.toString()+".json");
}
export function getUiData(language: LanguageEnum = LanguageEnum.English): Promise<AxiosResponse<UiData>> {
    console.log(language.toString());
    return axios.get(API_BASE_BASE+"/displaydata/UiDict-"+language.toString()+".json");
}
