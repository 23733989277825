export enum LanguageEnum {
    English = "en",
    Arabic = "ar",
    ChineseSimplified = "zh-s",
    ChineseTraditional = "zh-t",
    Dutch = "nl",
    French = "fr",
    German = "de",
    Hungarian = "hu",
    Italian = "it",
    Japanese = "jp",
    Polish = "pl",
    Portuguese = "pt",
    Vietnamese = "vi",
}
