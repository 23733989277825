import {GameDataDto} from "../../dto/GameDataDto";
import {BoardDto} from "../../dto/BoardDto";
import {DisplayData} from "../../dto/DisplayData";
import {UiData} from "../../dto/display/UiData";
import {JSX} from "react";
import {StatDisplay} from "../../dto/display/StatDisplay";
import {GetItemDescription} from "../../util/utilDescription";
import {ItemBase} from "../../dto/base/ItemBase";
import {ItemData} from "../../dto/data/ItemData";

export default function ItemElement(props: {
    data: GameDataDto,
    board: BoardDto,
    displayData: DisplayData,
    uiData: UiData,
    itemData?: ItemData,
    position: number, width: number, height: number,
    marginHalf: number,
    onHover: () => void,
}): JSX.Element {
    const statDisplay : StatDisplay|undefined = props.itemData && (props.displayData.items[props.itemData.name] ?? undefined);
    const statBase : ItemBase|undefined = props.itemData && (props.data.items[props.itemData.name] ?? undefined);

    let inner = <></>;
    if(props.itemData && statDisplay && statBase) {
        inner = <>
            <div className="hover-child border element-container" style={{
                right: props.width * 2.3 + "px",
                width: 2 * props.width + "px",
                //height: "120%",
                top: "17%",
                padding: "5%"
            }}>
                <div style={{marginTop: 0, marginBottom: "3%"}}><b>{statDisplay.displayName}</b></div>
                <hr/>
                <div style={{
                    height: "75%",
                    paddingRight: "1%",
                    marginBottom: "3%",
                    //overflowY: "scroll"
                }}
                     dangerouslySetInnerHTML={{__html: GetItemDescription(props.data, props.displayData, props.uiData, statDisplay, statBase, props.itemData)}}></div>
            </div>
        </>;
    }

    return <div className="hover-parent interactive" onMouseOver={props.onHover} style={{
        //position: "relative",
        width: props.width,
        height: props.height,
        backgroundColor: "#00000005",
        marginLeft: props.marginHalf + "px",
        marginRight: props.marginHalf + "px"
    }}>
        {inner}
    </div>;
}
