import {GameDataDto} from "../../dto/GameDataDto";
import {BoardDto} from "../../dto/BoardDto";
import {DisplayData} from "../../dto/DisplayData";
import {JSX} from "react";
import {StatDisplay} from "../../dto/display/StatDisplay";
import {GetTreasureDescription} from "../../util/utilDescription";
import {TreasureData} from "../../dto/data/TreasureData";
import {TreasureBase} from "../../dto/base/TreasureBase";
import {UiData} from "../../dto/display/UiData";

export default function TreasureElement(props: {
    data: GameDataDto,
    board: BoardDto,
    displayData: DisplayData,
    uiData: UiData,
    treasureData?: TreasureData,
    position: number, width: number, height: number,
    marginHalf: number,
    onHover: () => void,
}): JSX.Element {
    const statDisplay : StatDisplay|null = props.treasureData && props.displayData.treasures[props.treasureData.name] ? props.displayData.treasures[props.treasureData.name] : null;
    const statBase : TreasureBase|null = props.treasureData && props.data.treasures[props.treasureData.name] ? props.data.treasures[props.treasureData.name] : null;

    let inner = <></>;
    if(props.treasureData && statDisplay && statBase) {
        inner = <>
            <div className="hover-child border" style={{
                position: "relative",
                left: "-300%",
                backgroundColor: "#c5ac8f",
                width: "270%",
                //height: "120%",
                padding: "5%"
            }}>
                <div style={{marginTop: 0, marginBottom: "3%"}}><b>{statDisplay.displayName}</b></div>
                <hr/>
                <div style={{
                    height: "75%",
                    paddingRight: "1%",
                    marginBottom: "3%",
                    //overflowY: "scroll"
                }}
                     dangerouslySetInnerHTML={{__html: GetTreasureDescription(props.data, props.displayData, props.uiData, statDisplay, props.board, statBase, props.treasureData)}}></div>
            </div>
        </>;
    }

    return <div className="hover-parent interactive" onMouseOver={props.onHover} style={{
        //position: "relative",
        width: props.width,
        height: props.height,
        backgroundColor: "#00000005",
        marginLeft: props.marginHalf + "px",
        marginRight: props.marginHalf + "px"
    }}>
        {inner}
    </div>;
}