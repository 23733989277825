import {JSX} from "react";
import {GameDataDto} from "../../dto/GameDataDto";
import {BoardDto} from "../../dto/BoardDto";
import {DisplayData} from "../../dto/DisplayData";
import {HeroBase} from "../../dto/base/HeroBase";
import {StatDisplay} from "../../dto/display/StatDisplay";
import {GetHeroDescription} from "../../util/utilDescription";
import {UiData} from "../../dto/display/UiData";

export default function HeroElement(props: {
    data: GameDataDto,
    board: BoardDto,
    displayData: DisplayData,
    uiData: UiData,
    width: number, height: number,
    onHover: () => void,
}): JSX.Element {

    const split = props.board.hero.split(",");
    let inner = <>
        <div className="hover-child border element-container" style={{
            position: "absolute",
            left: "100%",
            top: 0,
            width: "120%",
            //height: "120%",
            padding: "5%"
        }}>
            {split.map((hName: string) => {
                const statDisplay: StatDisplay | undefined = props.displayData.heroes[hName] ?? undefined;
                const statBase: HeroBase | undefined = props.data.heroes[hName] ?? undefined;

                if (!statDisplay || !statBase) return <div key={hName}></div>;

                return <div key={hName} className="hover-child">
                    <div><b>{statDisplay.displayName}</b></div>
                    <hr/>
                    <div
                        dangerouslySetInnerHTML={{__html: GetHeroDescription(props.data, props.displayData, props.uiData, statDisplay, props.board, statBase)}}></div>
                </div>
            })}
        </div>
    </>;

    return <div className="hover-parent interactive" onMouseOver={props.onHover} style={{
        width: props.width,
        height: props.height,
        backgroundColor: "#00000005",
    }}>
        {inner}
    </div>;
}