import React from 'react';
import './App.css';
import BoardLoader from "./components/BoardLoader";

function App() {
  return (
    <div className="App">
      <BoardLoader />
    </div>
  );
}

export default App;
