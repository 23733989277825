import {JSX, useCallback, useEffect, useState} from "react";
import {GameDataDto} from "../dto/GameDataDto";
import Loading from "./Loading";
import {getBoard, getDisplayData, getGameData, getUiData} from "../service/DataService";
import {AxiosError, AxiosResponse} from "axios";
import {MessageDisplay, MessageState} from "./Message";
import HorizontalContainer from "./HorizontalContainer";
import CharacterElement from "./cards/CharacterElement";
import {DisplayData} from "../dto/DisplayData";
import {BoardDto} from "../dto/BoardDto";
import useWindowDimensions from "../hooks/useWindowDimensions";
import HeroElement from "./cards/HeroElement";
import TreasureElement from "./cards/TreasureElement";
import {UiData} from "../dto/display/UiData";
import {recordKeys} from "../util/utilFunctions";
import SpellElement from "./cards/SpellElement";
import ItemElement from "./cards/ItemElement";

export default function Board(props: {twitchName: string}): JSX.Element {
    const { viewportWidth, viewportHeight } = useWindowDimensions();
    
    const [error, errorSet] = useState(false);
    const [message, messageSet] = useState<MessageState>();
    const [displayData, displayDataSet] = useState<DisplayData>();
    const [uiData, uiDataSet] = useState<UiData>();
    const [data, dataSet] = useState<GameDataDto>();
    const [board, boardSet] = useState<BoardDto>();
    const [timeHover, timeHoverSet] = useState<Date>(new Date());

    // get data
    useEffect(() => {
        if(data) return;

        // request data
        getGameData(props.twitchName).then((response: AxiosResponse<GameDataDto>) => {
            dataSet(response.data);
        }, (error: AxiosError) => {
            errorSet(true);
        });
    }, [data, props.twitchName]);

    // get displayData
    useEffect(() => {
        if(displayData) return;

        // request data
        getDisplayData().then((response: AxiosResponse<DisplayData>) => {
            displayDataSet(response.data);
        }, (error: AxiosError) => {
            errorSet(true);
        });
    }, [displayData]);
    useEffect(() => {
        if(uiData) return;

        // request data
        getUiData().then((response: AxiosResponse<UiData>) => {
            uiDataSet(response.data);
        }, (error: AxiosError) => {
            errorSet(true);
        });
    }, [uiData]);

    /** load board */
    const boardLoad = useCallback(() => {
        getBoard(props.twitchName).then((response: AxiosResponse<BoardDto>) => {
            const board = response.data;
            if(board.heroSpecific===undefined) board.heroSpecific = {};
            if(board.custom===undefined) board.custom = {};
            boardSet(board);
        }, (error: AxiosError) => {
            messageSet({type: "danger", message: "An error occurred."});
        });
    }, [props.twitchName]);

    /** update board on hover (wait between loads) */
    const onHover = useCallback(() => {
        const milisecondsDifference = +new Date() - +timeHover;

        // request board only every X seconds
        if(milisecondsDifference<6 * 1000) return;
        timeHoverSet(new Date());
        console.log("Requesting new board.")
        boardLoad();
    }, [boardLoad, timeHover]);

    // initial board load
    useEffect(() => {
        boardLoad();
    }, [boardLoad]);

    if(error) {
        return <></>;
    }

    // still loading
    if(!data || !displayData || !uiData || !board) {
        return <>
            <MessageDisplay message={message}/>
            <div style={{color:"white"}}>Found streamer name. Loading info.</div>
            <Loading />
        </>;
    }

    // get sizes: want to force 16:9 inside viewport
    const aspectRatioIdeal = 16/9.0;
    const aspectRatioReal = viewportWidth / viewportHeight;

    // wider than ideal
    const scaleDebugFactor = 0.97;
    let width = viewportWidth * scaleDebugFactor;
    let height = viewportHeight * scaleDebugFactor;
    if(aspectRatioReal>aspectRatioIdeal) {
        width = height * aspectRatioIdeal;
    }
    // taller than ideal
    else {
        height = width / aspectRatioIdeal;
    }

    /** all other sizes scale with this number */
    const sizeReference = height/1000;

    // hero position
    const heroSize = sizeReference * 260;
    const heroLeft = width * 0.09;
    const heroBottom = height * 0.30;

    // board row numbers
    const cardWidth = sizeReference*162;
    const spellWidth = sizeReference*160;
    const itemWidth = sizeReference*120;
    const cardMargin = sizeReference*21;
    const cardHeight = sizeReference*200;
    const handSizeFactor = 0.75;
    const frontRowPosition = height * 0.423;
    const backRowPosition = height * 0.206;
    const handRowPosition = 0;
    const shopRowPosition = height * 0.652;

    // arrays for board
    const frontRowArray: number[] = [];
    for(let i=1; i<=data.lobbyData.chars_front; ++i) frontRowArray.push(i);
    const backRowArray: number[] = [];
    for(let i=data.lobbyData.chars_front+1; i<=data.lobbyData.chars_front+data.lobbyData.chars_back; ++i) backRowArray.push(i);
    const handRowArray: number[] = [];
    for(let i=data.lobbyData.chars_front+data.lobbyData.chars_back+1; i<=data.lobbyData.chars_front+data.lobbyData.chars_back+data.lobbyData.hand_size; ++i) handRowArray.push(i);

    // arrays for shop
    const shopCharsArray: number[] = recordKeys(board.shop.characters);
    const shopSpellsArray: number[] = recordKeys(board.shop.spells);
    const shopItemsArray: number[] = recordKeys(board.shop.items);
    const shopSize = shopCharsArray.length + shopSpellsArray.length + shopItemsArray.length;

    // treasures
    const treasureSize = sizeReference * 145;
    const treasureMargin = sizeReference * 5;
    const treasureCount = 3 + data.lobbyData.treasure_space_add + (board.heroSpecific[""] ?? 0);

    let treasureUp = (treasureCount-(treasureCount%2)) / 2;
    let treasureDown = treasureCount-treasureUp;
    const treasureRow1: number[] = [];
    const treasureRow2: number[] = [];
    const treasureRight = width * 0.1195;
    const treasure1Bottom = height * 0.42;
    const treasure2Bottom = height * 0.275;

    if(treasureCount < 5) {
        for(let i=0; i<treasureUp; ++i) treasureRow1.push(i);
        for(let i=treasureUp; i<treasureUp+treasureDown; ++i) treasureRow2.push(i);
    }
    else if(treasureCount===5)
    {
        treasureUp = 2;
        treasureDown = 3;

        for(let i=0; i<treasureUp; ++i) treasureRow1.push(i);
        for(let i=treasureUp; i<treasureUp+treasureDown; ++i) treasureRow2.push(i);
    }

    return <div className="non-interactive" style={{
        position: "absolute",
        top: 0,
        left: (viewportWidth - width) / 2,
        width: width + "px",
        height: height + "px",
        overflow: "hidden",

        /*backgroundImage: "url(/UI-test.webp)",
        backgroundSize: "cover"*/
    }}>
        <MessageDisplay message={message}/>

        {/* shop spaces */}
        <div style={{
            position: "absolute",
            bottom: shopRowPosition + "px",
            left: (width * 0.515 - shopSize * (cardWidth + cardMargin) / 2) + "px"
        }}>
            <HorizontalContainer>
                <>
                    {shopCharsArray.map((pos: number) => <CharacterElement key={pos} data={data} board={board}
                                                                           displayData={displayData}
                                                                           uiData={uiData}
                                                                           charData={board.shop.characters[pos]}
                                                                           position={pos} width={cardWidth}
                                                                           height={cardHeight}
                                                                           marginHalf={cardMargin / 2} onHover={onHover}/>)}
                    {shopSpellsArray.map((pos: number) => <SpellElement key={pos} data={data} board={board}
                                                                           displayData={displayData}
                                                                           uiData={uiData}
                                                                           spellData={board.shop.spells[pos]}
                                                                           position={pos} width={spellWidth}
                                                                           height={cardHeight}
                                                                           marginHalf={cardMargin / 2} onHover={onHover}/>)}
                    {shopItemsArray.map((pos: number) => <ItemElement key={pos} data={data} board={board}
                                                                           displayData={displayData}
                                                                           uiData={uiData}
                                                                           itemData={board.shop.items[pos]}
                                                                           position={pos} width={itemWidth}
                                                                           height={cardHeight}
                                                                           marginHalf={cardMargin / 2} onHover={onHover}/>)}
                </>
            </HorizontalContainer>
        </div>

        {/* front row spaces */}
        <div style={{
            position: "absolute",
            bottom: frontRowPosition + "px",
            left: width / 2 - frontRowArray.length * (cardWidth + cardMargin) / 2 + "px"
        }}>
            <HorizontalContainer>
                {frontRowArray.map((pos: number) => <CharacterElement key={pos} data={data} board={board}
                                                                      displayData={displayData}
                                                                      uiData={uiData}
                                                                      charData={board.characters[pos]}
                                                                      position={pos} width={cardWidth}
                                                                      height={cardHeight}
                                                                      marginHalf={cardMargin / 2} onHover={onHover}/>)}
            </HorizontalContainer>
        </div>
        {/* back row spaces */}
        <div style={{
            position: "absolute",
            bottom: backRowPosition + "px",
            left: width / 2 - backRowArray.length * (cardWidth + cardMargin) / 2 + "px"
        }}>
            <HorizontalContainer>
                {backRowArray.map((pos: number) => <CharacterElement key={pos} data={data} board={board}
                                                                     displayData={displayData}
                                                                     uiData={uiData}
                                                                     charData={board.characters[pos]}
                                                                     position={pos} width={cardWidth}
                                                                     height={cardHeight}
                                                                     marginHalf={cardMargin / 2} onHover={onHover}/>)}
            </HorizontalContainer>
        </div>
        {/* hand row spaces */}
        <div style={{
            position: "absolute",
            bottom: handRowPosition + "px",
            left: width / 2 - handRowArray.length * (cardWidth + cardMargin) * handSizeFactor / 2 + "px"
        }}>
            <HorizontalContainer>
                {handRowArray.map((pos: number) => <CharacterElement key={pos} data={data} board={board}
                                                                     displayData={displayData}
                                                                     uiData={uiData}
                                                                     charData={board.characters[pos]}
                                                                     position={pos} width={handSizeFactor * cardWidth}
                                                                     height={handSizeFactor * cardHeight}
                                                                     marginHalf={handSizeFactor * cardMargin / 2}
                                                                     onHover={onHover}/>)}
            </HorizontalContainer>
        </div>

        {/* treasure first row */}
        <div style={{
            position: "absolute",
            bottom: treasure1Bottom + "px",
            right: treasureRight - treasureRow1.length * (treasureSize + treasureMargin) / 2 + "px"
        }}>
            <HorizontalContainer>
                {treasureRow1.map((pos: number) => <TreasureElement key={pos} data={data} board={board}
                                                                    displayData={displayData}
                                                                    uiData={uiData}
                                                                    treasureData={board.treasures[pos] ?? undefined}
                                                                    position={pos} width={treasureSize}
                                                                    height={treasureSize}
                                                                    marginHalf={treasureMargin / 2}
                                                                    onHover={onHover}/>)}
            </HorizontalContainer>
        </div>
        {/* treasure second row */}
        <div style={{
            position: "absolute",
            bottom: treasure2Bottom + "px",
            right: treasureRight - treasureRow2.length * (treasureSize + treasureMargin) / 2 + "px"
        }}>
            <HorizontalContainer>
                {treasureRow2.map((pos: number) => <TreasureElement key={pos} data={data} board={board}
                                                                    displayData={displayData}
                                                                    uiData={uiData}
                                                                    treasureData={board.treasures[pos] ?? undefined}
                                                                    position={pos} width={treasureSize}
                                                                    height={treasureSize}
                                                                    marginHalf={treasureMargin / 2}
                                                                    onHover={onHover}/>)}
            </HorizontalContainer>
        </div>

        {/* hero */}
        <div style={{position: "absolute", bottom: heroBottom + "px", left: heroLeft + "px"}}>
            <HeroElement data={data} board={board} displayData={displayData} uiData={uiData} width={heroSize}
                         height={heroSize}
                         onHover={onHover}/>
        </div>
    </div>;
}
