import {GameDataDto} from "../dto/GameDataDto";
import {BoardDto} from "../dto/BoardDto";
import {CharacterBase} from "../dto/base/CharacterBase";
import {CharData} from "../dto/data/CharData";
import {HeroBase} from "../dto/base/HeroBase";
import {ItemBase} from "../dto/base/ItemBase";
import {ItemData} from "../dto/data/ItemData";
import {TreasureBase} from "../dto/base/TreasureBase";
import {TreasureData} from "../dto/data/TreasureData";
import {SpellBase} from "../dto/base/SpellBase";
import {StatDisplay} from "../dto/display/StatDisplay";
import {DisplayData} from "../dto/DisplayData";
import {UiData} from "../dto/display/UiData";

const operation = (operationString: string): ((a: number, b: number) => boolean) | null => {
    if (operationString.length > 1) {
        const op = operationString.substring(0, Math.min(2, operationString.length));
        switch (op) {
            case '>=': return (a, b) => a >= b;
            case '<=': return (a, b) => a <= b;
            case '!=': return (a, b) => a !== b;
            case '==': return (a, b) => a === b;
            default: break;
        }
    }

    const singleOp = operationString.substring(0, 1);
    switch (singleOp) {
        case '>': return (a, b) => a > b;
        case '<': return (a, b) => a < b;
        case '=': return (a, b) => a === b;
        default: return null;
    }
};

const operationLength = (operationString: string): number => {
    const op = operationString.substring(0, 2);
    switch (op) {
        case '>=':
        case '<=':
        case '!=':
        case '==': return 2;
        default: break;
    }

    const singleOp = operationString.substring(0, 1);
    switch (singleOp) {
        case '>':
        case '<':
        case '=': return 1;
        default: return 0;
    }
};


const removeUnderline = (desc: string): string => {
    const regex = /<u>[^>]+<\/u>/g;
    return desc.replace(regex, match => match.substring(3, match.length - 4));
};


type StatBase = {
    name: string;
    stat1: number;
    stat2: number;
    stat3: number;
    // ... other properties ...
};

// Define CharData, BoardDto, TreasureData based on your data structures

const summonText = (data: GameDataDto, displayData: DisplayData, summonCount: number, summonName: string, upgraded: boolean = false): string => {
    const summonBase = data.characters[summonName];
    if (!summonBase) {
        console.error(`Character ${summonName} not found`);
        return '';
    }

    const summonDisplay = displayData.characters[summonName];
    if (!summonDisplay) {
        console.error(`Display data for character ${summonName} not found`);
        return '';
    }

    const atk = summonBase.attack;
    const hp = summonBase.hp;

    return `${summonCount === 1 ? 'a' : summonCount.toString()} ${atk}/${hp} ${summonDisplay.displayName}`;
};


const replace = (
    data: GameDataDto,
    displayData: DisplayData,
    uiData: UiData,
    statDisplay: StatDisplay,
    matchString: string,
    statBase: StatBase,
    character?: CharData,
    board?: BoardDto,
    treasure?: TreasureData
): string => {
    let replacement = '';
    const matchContent = matchString.substring(1, matchString.length - 1);
    const split = matchContent.split(';');
    const secondPart = split.length > 2 ? split[2] : '';

    const parseAndCalc = (aStr: string, bStr: string, operation: (a: number, b: number) => boolean): string => {
        const a = parseInt(aStr);
        const b = parseInt(bStr);
        return operation(a, b) ? split[4] : (split.length >= 6 ? split[5] : '');
    };

    if (split[0] === '?') {
        const opFn = operation(split[2]);
        if (opFn) {
            replacement = parseAndCalc(split[1], split[3], opFn);
        } else {
            console.error(`Could not parse ternary of ${statBase.name}: ${matchString}`);
        }
    }
    else if (split[0] === 'stat1') {
        replacement = statBase.stat1.toString();
    }
    else if (split[0] === 'stat2') {
        replacement = statBase.stat2.toString();
    }
    else if (split[0] === 'stat3') {
        replacement = statBase.stat3.toString();
    }
    else if (split[0] === 'stat1S') {
        replacement = statBase.stat1===1 ? "" : "s";
    }
    else if (split[0] === 'stat2S') {
        replacement = statBase.stat2===1 ? "" : "s";
    }
    else if (split[0] === 'stat3S') {
        replacement = statBase.stat3===1 ? "" : "s";
    }
    else if (split[0] === 'up') {
        replacement = character && character.upgradedCurrent ? split[1] : secondPart;
    }
    else if (split[0] === 'quest') {
        replacement = character && !character.questComplete ? split[1] : secondPart;
    }
    else if (split[0].startsWith("char")) {
        const cName = split[1];
        let count = 1;
        if (secondPart.length > 0) {
            const val = parseInt(secondPart);
            if (!isNaN(val)) {
                count = val;
            } else {
                console.log(`StatDisplay${statDisplay.displayName}: could not parse number ${secondPart}`);
            }
        }

        // Assuming SummonText is a function defined elsewhere
        replacement = summonText(data, displayData, count, cName, character != null && character.upgradedCurrent);
        if (replacement.length > 0 && replacement[replacement.length - 1] === '.') {
            replacement = replacement.slice(0, -1);
        }
    }
    else if (split[0] === "spell") {
        const cName = split[1];
        const display = displayData.spells[cName];
        replacement = `${display.displayName} (${GetSpellDescription(data, displayData, uiData, displayData.spells[cName], data.spells[cName], false)})`;
        if (replacement[replacement.length - 1] === '.') {
            replacement = replacement.slice(0, -1);
        }
    }
    else if (split[0] === "treasure") {
        const cName = split[1];
        const display = displayData.treasures[cName];
        if(board) replacement = `${display.displayName} (${removeUnderline(GetTreasureDescription(data, displayData, uiData, displayData.treasures[cName], board, data.treasures[cName]))})`;
        else replacement = "";
        if (replacement[replacement.length - 1] === '.') {
            replacement = replacement.slice(0, -1);
        }
    }
    else if (split[0].startsWith("-")) {
        let value = 0;
        if (split.length <= 2) {
            const nr = parseInt(split[1]);
            if (!isNaN(nr)) {
                value = -nr;
            }
        } else {
            const a = parseInt(split[1]);
            const b = parseInt(secondPart);
            if (!isNaN(a) && !isNaN(b)) {
                value = a - b;
            }
        }
        replacement = value.toString();
    }
    else if (split[0].startsWith("*")) {
        let value = 0;
        const a = parseInt(split[1]);
        const b = parseInt(secondPart);
        if (!isNaN(a) && !isNaN(b)) {
            value = a * b;
        }
        replacement = value.toString();
    }
    else if (split[0].startsWith("+")) {
        let value = 0;
        const a = parseInt(split[1]);
        const b = parseInt(secondPart);
        if (!isNaN(a) && !isNaN(b)) {
            value = a + b;
        }
        replacement = value.toString();
    }
    else if (split[0].startsWith("/") || split[0].startsWith(":")) {
        let value = 0;
        const a = parseInt(split[1]);
        const b = parseInt(secondPart);
        if (!isNaN(a) && !isNaN(b) && b !== 0) {
            value = a / b;
        }
        replacement = value.toString();
    }
    else if (split[0].startsWith("%")) {
        let value = 0;
        const a = parseInt(split[1]);
        const b = parseInt(secondPart);
        if (!isNaN(a) && !isNaN(b) && b > 0) {
            value = (a / b) * 100;
        }
        replacement = `${value.toFixed(0)}%`;
    }
    else if (split[0] === "persist") {
        let persist: { [key: string]: number } | null = null;
        if (character != null) {
            persist = character.persist;
        } else if (treasure != null) {
            persist = treasure.persist;
        }

        if (persist != null) {
            const key = split[1];
            const value = persist[key];
            if (value !== undefined) {
                replacement = value.toString();
            } else {
                replacement = secondPart;
            }
        }
    }
    else if (split[0] === "custom") {
        let custom: { [key: string]: number } | null = null;
        if (character != null) {
            custom = character.custom;
        } else if (treasure != null) {
            custom = treasure.custom;
        }

        if (custom != null) {
            const key = split[1];
            const value = custom[key];
            if (value !== undefined) {
                replacement = value.toString();
            } else {
                replacement = secondPart;
            }
        }
    }
    else if (split[0] === "board") {
        if (board!==undefined && split.length>0 && board.heroSpecific[split[1]] !== undefined) {
            replacement = board.heroSpecific[split[1]].toString();
        } else {
            replacement = secondPart;
        }
    }
    else if (split[0] === "boardCustom") {
        if (board!==undefined && split.length>0 && board.custom[split[1]] !== undefined) {
            replacement = board.custom[split[1]].toString();
        } else {
            replacement = secondPart;
        }
    }
    else if (split[0] === "cmp") {
        if (split.length < 5) {
            replacement = "";
        } else {
            const nr = parseInt(split[1]);
            const op = operation(split[2]);
            const nr2 = parseInt(split[3]);
            if (op && !isNaN(nr) && !isNaN(nr2)) {
                replacement = op(nr, nr2) ? split[4] : (split.length >= 6 ? split[5] : "");
            } else {
                console.log(`StatDisplay: Could not parse match ${matchString} of ${statBase.name}`);
            }
        }
    }
    else if (split[0] === "pos") {
        if (character != null) {
            replacement = character.positionCurrent.toString();
        }
    }
    else if (split[0] === "abs") {
        const nr = parseInt(split[1]);
        if (!isNaN(nr)) {
            replacement = Math.abs(nr).toString();
        }
    }
    else if (split[0] === "counter") {
        if (treasure != null) {
            replacement = treasure.counter.toString();
        } else {
            replacement = secondPart;
        }
    }
    else if (split[0] === "oc") {
        const nr = parseInt(split[1]);
        if (!isNaN(nr)) {
            switch (nr)
            {
                case 1:
                case 2:
                {
                    replacement = uiData["repeat-" + nr];
                    break;
                }
                default:
                {
                    replacement = uiData["repeat-n"];
                    break;
                }
            }
        }
    }
    else {
        console.error(`Unknown replacement key: ${split[0]}`);
        replacement = "";
    }

    return replacement;
};

const getDescription = (
    data: GameDataDto,
    displayData: DisplayData,
    uiData: UiData,
    statDisplay: StatDisplay,
    desc: string,
    statBase: StatBase,
    character?: CharData,
    board?: BoardDto,
    treasure?: TreasureData
): string => {
    desc = desc.replaceAll("{", "[");
    desc = desc.replaceAll("}", "]");

    // Example of replacing color codes - adjust regex as needed
    // desc = desc.replace(/\-?(\d+)\/\-?(\d+)/g, '<color=#6b5200>$1</color>/<color=#700a07>$2</color>');

    // Replace based on custom logic
    const regex = /\[[^\[\]]+\]/g; // warning "Unnecessary escape character: \[" is wrong
    while(true) {
        let descAfter = desc.replace(regex, match => replace(data, displayData, uiData, statDisplay, match, statBase, character, board, treasure));
        if(desc===descAfter) break;
        desc = descAfter;
    }

    return desc;
};

export function GetDescription(data: GameDataDto, displayData: DisplayData, statDisplay: StatDisplay, desc: string, statBase: HeroBase, character?: CharData, board?: BoardDto, treasure?: TreasureData): string {
    return "Test";
}

export function GetHeroDescription(data: GameDataDto, displayData: DisplayData, uiData: UiData, statDisplay: StatDisplay, board: BoardDto, statBase: HeroBase): string {
    let desc = statDisplay.description;
    desc = getDescription(data, displayData, uiData, statDisplay, desc, statBase, undefined, board);
    return desc;
}
export function getCharacterDescription(data: GameDataDto, displayData: DisplayData, uiData: UiData, statDisplay: StatDisplay, board: BoardDto, statBase: CharacterBase, character: CharData): string {
    const statB: CharacterBase = JSON.parse(JSON.stringify(statBase));
    if(character.upgraded || character.upgradedCurrent) {
        if(statDisplay.upStats) {
            if(statDisplay.upStats.includes("1")) statB.stat1 *= 2;
            if(statDisplay.upStats.includes("2")) statB.stat2 *= 2;
            if(statDisplay.upStats.includes("3")) statB.stat3 *= 2;
        }
        else {
            statB.stat1 *= 2;
            statB.stat2 *= 2;
        }
    }

    let desc = statDisplay.description;

    desc = getDescription(data, displayData, uiData, statDisplay, desc, statB, character, board);

    if(character.keywordsCurrent) {
        const split = character.keywordsCurrent.split(" ");
        let kDisplay = "";
        split.forEach(k => {
            if(k.length<=1) return;
            kDisplay += k.substring(0,1).toUpperCase() + k.substring(1) + " ";
        })

        if(kDisplay.length>0) desc = kDisplay + "\n" + desc;
    }

    return desc;
}
export function getCharacterTypes(data: GameDataDto, displayData: DisplayData, statDisplay: StatDisplay, board: BoardDto, statBase: CharacterBase, character: CharData): string {
    let desc = "";
    if(character.alignmentCurrent>0) desc = "Good ";
    if(character.alignmentCurrent<0) desc = "Evil ";
    else desc = "Neutral ";

    const cStats = data.characters[character.name];

    const split = cStats.race.split(" ");
    split.forEach(k => {
        if(k.length<=1) return;
        desc += k.substring(0,1).toUpperCase() + k.substring(1) + " ";
    })
    return desc;
}
export function GetTreasureDescription(data: GameDataDto, displayData: DisplayData, uiData: UiData, statDisplay: StatDisplay, board: BoardDto, statBase: TreasureBase, treasure?: TreasureData): string {
    let desc = statDisplay.description;
    desc = getDescription(data, displayData, uiData, statDisplay, desc, statBase, undefined, board);

    if (board != null && board.heroSpecific != null && treasure!=null &&
        board.heroSpecific["baby mimic:" + treasure.name])
    {
        desc += "<br/><b>Effect strengthened</b> by " + displayData.treasures["baby mimic"].displayName + ".";
    }

    return desc;
}
export function GetSpellDescription(data: GameDataDto, displayData: DisplayData, uiData: UiData, statDisplay: StatDisplay, statBase: SpellBase, ownDescription: boolean): string {
    let desc = statDisplay.description;
    desc = getDescription(data, displayData, uiData, statDisplay, desc, statBase, undefined, undefined);
    return desc;
}
export function GetItemDescription(data: GameDataDto, displayData: DisplayData, uiData: UiData, statDisplay: StatDisplay, statBase: ItemBase, itemData: ItemData): string {
    let desc = statDisplay.description;
    desc = getDescription(data, displayData, uiData, statDisplay, desc, statBase, undefined, undefined);
    return desc;
}