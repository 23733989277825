import axios, {AxiosResponse} from "axios";

const API = "https://api.twitch.tv"

export interface TwitchAuthDto {
    channelId: string;
    clientId: string;
    helixToken: string;
    token   : string;
    userId: string;
}

export interface TwitchBroadcasterDtoOuter {
    data: TwitchBroadcasterDto[];
}

export interface TwitchBroadcasterDto {
    broadcaster_name: string;
}

export function getTwitchRequest(auth: TwitchAuthDto): Promise<AxiosResponse<TwitchBroadcasterDtoOuter>> {
    return axios.get(API + "/helix/channels?broadcaster_id="+auth.channelId, {
        headers: {
            "client-id": auth.clientId,
            "Authorization": "Extension "+auth.helixToken,
        }
    });
}
