import {JSX, useEffect, useState} from "react";
import Loading from "./Loading";
import Board from "./Board";
import {AxiosError, AxiosResponse} from "axios";
import {
    getTwitchRequest,
    TwitchAuthDto,
    TwitchBroadcasterDtoOuter
} from "../service/TwitchService";
import { useLocation } from "react-router-dom";


declare global {
    interface Window {
        Twitch: any;
    }
}

export default function BoardLoader(): JSX.Element {
    const [twitchName, twitchNameSet] = useState<string|null>();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const streamerName = queryParams.get('streamer');

        // for embedding on normal website
        if(streamerName) {
            twitchNameSet(streamerName);
        }
        // for twitch extension
        else if (window.Twitch && window.Twitch.ext) {
            console.log("Twitch.ext exists", window.Twitch.ext, location);
            window.Twitch.ext.onAuthorized((auth: TwitchAuthDto) => {
                console.log(auth);

                getTwitchRequest(auth).then((response: AxiosResponse<TwitchBroadcasterDtoOuter>) => {
                    console.log(response);

                    twitchNameSet(response.data.data[0].broadcaster_name);
                }, (error: AxiosError) => {
                    // messageSet({type: "danger", message: "An error occurred."});
                    twitchNameSet(null);
                });
            });
        }
    }, [location, location.search]);

    if(!twitchName) return <></>;
    return <>
        <Board twitchName={twitchName}/>
    </>;
}