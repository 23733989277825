import {JSX} from "react";
import {CharData} from "../../dto/data/CharData";
import {DisplayData} from "../../dto/DisplayData";
import {StatDisplay} from "../../dto/display/StatDisplay";
import {GameDataDto} from "../../dto/GameDataDto";
import {getCharacterDescription, getCharacterTypes} from "../../util/utilDescription";
import {BoardDto} from "../../dto/BoardDto";
import {CharacterBase} from "../../dto/base/CharacterBase";
import {UiData} from "../../dto/display/UiData";

export default function CharacterElement(props: {
    data: GameDataDto,
    board: BoardDto,
    displayData: DisplayData,
    uiData: UiData,
    charData?: CharData,
    position: number, width: number, height: number,
    marginHalf: number,
    onHover: () => void,
}): JSX.Element {
    const statDisplay : StatDisplay|undefined = props.charData && (props.displayData.characters[props.charData.name] ?? undefined);
    const statBase : CharacterBase|undefined = props.charData && (props.data.characters[props.charData.name] ?? undefined);

    let inner = <></>;
    if(props.charData && statDisplay && statBase) {
        inner = <>
            <div className="hover-child border element-container" style={{
                left: "100%",
                width: "270%",
                //height: "120%",
                padding: "5%"
            }}>
                <div style={{marginTop: 0, marginBottom: "1%"}}><b>{statDisplay.displayName}</b></div>
                <hr/>
                <div style={{
                    height: "75%",
                    paddingRight: "1%",
                    marginBottom: "3%",
                    //overflowY: "scroll"
                }}
                     dangerouslySetInnerHTML={{__html: getCharacterDescription(props.data, props.displayData, props.uiData, statDisplay, props.board, statBase, props.charData)}}></div>
                <hr/>
                <div>{getCharacterTypes(props.data, props.displayData, statDisplay, props.board, statBase, props.charData)}</div>
            </div>
        </>;
    }

    return <div className="hover-parent interactive" onMouseOver={props.onHover} style={{
        //position: "relative",
        width: props.width,
        height: props.height,
        backgroundColor: "#00000005",
        marginLeft: props.marginHalf + "px",
        marginRight: props.marginHalf + "px"
    }}>
        {inner}
    </div>;
}
